import { Box, createTheme, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import TopProducts from "../Components/Topproducts/TopProducts.js";
import FoodItems from "../Components/FoodItems/FoodItems";
import Gallery from "../Components/Gallery/Gallery";
import Header from "../Components/Header/Header";
import Testimonial from "../Components/Testimonials/Testimonial";
import VideoSection from "../Components/VideoSection/VideoSection.js";
import Footer from "../Components/Footer/Footer.js";

export default function HomePage() {
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container maxWidth={breakpointIsMatchlg ? "xl" : "lg"}>
        <Header />

        <Box
          sx={{
            mt: {
              xs: 3,
              md: 5,
            },
            pt: {
              xs: 3,
              md: 5,
            },
          }}
        >
          <Typography
            variant="h4"
            className="fw-semibold text-center mt-5 mb-3 mt-md-3"
          >
            Popular Products
          </Typography>
          <TopProducts />
        </Box>

        <Box
          sx={{
            mt: {
              xs: 0,
              md: 5,
            },
            pt: {
              xs: 0,
              md: 5,
            },
          }}
        >
          <FoodItems />
        </Box>

        <Box
          sx={{
            mt: {
              xs: 3,
              md: 5,
            },
            pt: {
              xs: 3,
              md: 5,
            },
          }}
        >
          <Gallery />
        </Box>
        <Box
          sx={{
            my: {
              xs: 3,
              md: 5,
            },
            pt: {
              xs: 3,
              md: 5,
            },
          }}
        >
          <VideoSection />
        </Box>
        <Box
          sx={{
            my: {
              xs: 3,
              md: 5,
            },
            pt: {
              xs: 3,
              md: 5,
            },
          }}
        >
          <Testimonial />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
