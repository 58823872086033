import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successStatus: false,
  demo: true,
};

export const contactFormSlice = createSlice({
  name: "contactForm",
  initialState,
  reducers: {
    setSuccessState: (state, action) => {
      state.successStatus = action.payload.successStatus;
    },
  },
});

export const { setSuccessState } = contactFormSlice.actions;

export const contactFormState = (state) => state.contactForm;

export default contactFormSlice.reducer;
