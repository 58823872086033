import React from "react";
import { Route, Routes } from "react-router-dom";
import Aboutpage from "../Pages/Aboutpage.js";
import Contactpage from "../Components/EmailForm/Contactpage.js";
import FAQs from "../Pages/FAQs.js";
import Gallery from "../Pages/Gallery.js";
import HomePage from "../Pages/HomePage.js";
import Product from "../Pages/Product.js";
import Sitemap from "../Pages/Sitemap.js";

export default function route() {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/product" element={<Product />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/aboutus" element={<Aboutpage />} />
        <Route path="/contactus" element={<Contactpage />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/sitemap" element={<Sitemap />} />
      </Route>
    </Routes>
  );
}
