import * as React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Avatar,
} from "@mui/material";
import {} from "react-bootstrap";
import { Box } from "@mui/system";
export default function Testimoni({ name, place, src, desc, date }) {
  return (
    <>
      <Card
        sx={{
          backgroundColor: "#efefef12",
          color: "#fff",
          width: "100%",
          height: "100%",
        }}
      >
        <Box className="d-flex ">
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: "80px", height: "80px", borderRadius: "1px" }}
              >
                <img src={src} alt=".." className="img-fluid" />
              </Avatar>
            }
          />
          <Box className="my-auto">
            <Typography variant="h6" color="var(--color-golden)">
              {name}
            </Typography>
            <Typography variant="body2" component="div" textAlign="start">
              {place}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="#eee9"
            className="ms-auto my-auto pe-3 pt-4"
          >
            {date}
          </Typography>
        </Box>
        <CardContent
          sx={{
            width: {
              md: "calc(100% - 110px)",
              xs: "auto",
            },
            marginLeft: "auto",
            pt: 0,
          }}
        >
          <Typography variant="body2" color="#fff" textAlign="start">
            {desc}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
