import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper";
import { Box } from "@mui/material";

import schoolLucnh from "../../assets/schoollunch.jpg";
import FishPower from "../../assets/FishPower1.jpg";

const gallery = [
  "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops1.jpg?alt=media&token=3f35d238-196a-4fbb-9059-541be02cb435",
  "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ShrimpPops2.jpg?alt=media&token=ac8af87b-a5f3-4bfc-ad63-3d0659f3b83a",
  "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops3.jpg?alt=media&token=2f85f58a-f801-46e8-98a6-d832845fa6d2",
  "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ShrimpPops1.jpg?alt=media&token=852a9bf4-8f22-4954-b8d1-052351aafa34",
  "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/pulav.jpg?alt=media&token=6b1e8df2-d520-40c4-a20e-2ee18595c818",
  schoolLucnh,
  FishPower,
];
function GalleryCarousal() {
  return (
    <Swiper
      id="gallerySwiper"
      navigation={true}
      slidesPerView={1}
      spaceBetween={10}
      autoplay={{
        delay: 2500,
      }}
      modules={[Navigation, Autoplay]}
      className="mySwiper my-0"
      breakpoints={{
        300: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      }}
    >
      {gallery?.map((item, index) => {
        return (
          <SwiperSlide key={index} style={{ background: "none" }}>
            <Box
              sx={{
                height: {
                  md: "447px",
                  xs: "300px",
                },
              }}
            >
              <img
                src={item}
                alt=".."
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  opacity: 1,
                }}
              />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default GalleryCarousal;
