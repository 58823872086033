import {
  Container,
  createTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Footer from "../Components/Footer/Footer.js";

export default function Aboutpage() {
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container className="my-5" maxWidth={breakpointIsMatchlg ? "xl" : "lg"}>
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-start my-5"
        >
          About Us
        </Typography>
        <Typography variant="h6" color="#fff" className="text-start mb-2">
          Celebrate food with a unique combination of Far-eastern inspiration
          with a magical touch of traditional Mangalorean taste.
        </Typography>
        <Typography variant="h6" color="#fff" className="text-start mb-2">
          The tagline “Flavours redefined” ensures that the work on food
          sustenance, minimal processing and most importantly humane treatment
          of the animal.
        </Typography>

        <Typography variant="h6" color="#fff" className="text-start mb-2">
          The Protein Values range predominantly can be consumed across QSR,
          Fine Dining and at the same time cater to the needs of a end-consumer
          through consistency in taste and quality.
        </Typography>
        <Typography variant="h6" color="#fff" className="text-start mb-3">
          Our objective is to reach food lovers across the country ensuring
          customer satisfaction while ensuring the need to focus on sustainable
          development, thus becoming a leader through innovative solutions
          reaching the right target customers.
        </Typography>
        <Typography variant="h6" color="#fff" className="text-start mb-2">
          We hold the distribution rights of SRF products to the entire South
          India. Items listed are Panko crumbs, Panko croutons, batters and
          flour.
        </Typography>
      </Container>
      <Footer />
    </>
  );
}
