import React from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Typography } from "@mui/material";
import SubHeading from "../SubHeading/Subheading";
import { useNavigate } from "react-router-dom";
import GalleryCarousal from "./GalleryCarousal";
function Gallery() {
  const navigate = useNavigate();
  return (
    <>
      <Row className="g-5 m-0 g-md-0">
        <Col
          md={6}
          className="d-flex flex-column justify-content-center gap-2 p-3"
        >
          <SubHeading title="Our Collection" />
          <Typography variant="h2" className="fw-semibold">
            {" Photo Gallery "}
          </Typography>
          <Typography variant="body1" className="text-white pe-4">
            Welcome to our mouth-watering photo gallery! We've carefully curated
            a collection of stunning images showcasing our delicious dishes that
            will leave you drooling.
            <br />
            Our photo gallery has something for everyone.
          </Typography>
          <Button
            className="button mt-2 me-auto px-3"
            onClick={() => navigate("/gallery")}
          >
            View More
          </Button>
        </Col>
        <Col xs={12} md={6}>
          <GalleryCarousal />
        </Col>
      </Row>
    </>
  );
}

export default Gallery;
