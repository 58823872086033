import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import FoodItemCard from "./FoodItemCard";
function FoodItemCarousal(props) {
  return (
    <>
      <Swiper
        id="FoodItemCarousal"
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
      >
        {props.list?.map(({ name, src, desc }, index) => {
          return (
            <SwiperSlide
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#efefef12",
                backdropfilter: "blur(12px)",
              }}
              key={index}
            >
              <FoodItemCard name={name} src={src} desc={desc} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default FoodItemCarousal;
