import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";
import { HashLink } from "react-router-hash-link";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#000",
  backgroundColor: "#f8b04a",
  border: "1px solid transparent",
  fontFamily: "Optima, Arial",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#f8b04a",
    border: "1px solid #f8b04a",
  },
}));

export default function App({ path, name, src, desc }) {
  return (
    <>
      <Card
        sx={{ backgroundColor: "transparent" }}
        className="d-flex flex-column justify-content-center align-items-center px-2 py-4"
      >
        <CardMedia
          sx={{
            borderRadius: "50%",
            overflow: "hidden",
            width: "150px",
            boxShadow: "0 4px 4px rgba(248 ,176 ,74, 0.5)",
          }}
        >
          <img
            src={src}
            alt=".."
            className="img-fluid"
            style={{
              aspectRatio: "4/4",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </CardMedia>
        <CardContent className="d-flex flex-column justify-content-center align-items-center pt-3 pb-1">
          <Typography variant="h6" className="m-0" color="var(--color-golden)">
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="#edededd1"
            className="text-center "
          >
            {desc}
          </Typography>
        </CardContent>
        <CardActions disableSpacing className="mt-auto mx-auto">
          <ColorButton variant="contained" className="fw-bold">
            <HashLink
              smooth
              to={path}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {" Explore More"}
            </HashLink>
          </ColorButton>
        </CardActions>
      </Card>
    </>
  );
}
