import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import Card from "../Card/Card.js";
import Data from "../../Constant/Constant.js";

export default function TopProducts() {
  return (
    <>
      <Swiper
        id="mealCarousal"
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
        }}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper my-0"
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {Data.topProducts?.map(({ id, path, name, src, desc }, index) => {
          return (
            <SwiperSlide
              className="rounded "
              key={index}
              style={{ background: "none" }}
            >
              <Card path={path} name={name} src={src} desc={desc} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
