import React from "react";
import { Box, Typography, Card, CardMedia, CardContent } from "@mui/material";
export default function Products(props) {
  return (
    <>
      {props.name && (
        <Typography
          id={props.item.id}
          variant="h4"
          color="var(--color-golden)"
          className="text-center my-5"
          sx={{ textShadow: "0px 0px 4px rgba(248 ,176 ,74, 0.5)" }}
        >
          {props.name}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.item.map(({ id, name, src, desc }, index) => {
          return (
            <Card
              key={index + id}
              id={id}
              sx={{
                maxWidth: "17rem",
                alignSelf: "normal",
                backgroundColor: "#efefef12",
                backdropfilter: "blur(12px)",
              }}
            >
              <CardMedia
                sx={{
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <img
                  src={src}
                  alt=".."
                  style={{
                    aspectRatio: "16/12",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="img-fluid"
                />
              </CardMedia>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="var(--color-golden)"
                >
                  {name}
                </Typography>
                <Typography variant="body2" color="#fff">
                  {desc}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </>
  );
}
