import {
  Container,
  createTheme,
  ThemeProvider,
  Grid,
  Stack,
  Typography,
  Badge,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import LinkIcon from "@mui/icons-material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "var(--color-golden)",
          textDecoration: "none",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            color: "var(--color-golden)",
          },
        },
      },
    },
  },
});
const customstyle = {
  color: "var(--color-golden)",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    color: "var(--color-golden)",
  },
};
export default function Sitemap() {
  const navigate = useNavigate();
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container
        sx={{ my: 4, pb: 4 }}
        maxWidth={breakpointIsMatchlg ? "xl" : "lg"}
      >
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-start my-5"
        >
          Sitemap
        </Typography>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignContent="center"
        >
          {/* ------------landing Page------------ */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> Landing Page
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 4 }}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/")}
                >
                  <Badge badgeContent={1} sx={{ mr: 1, bgcolor: "#333" }}>
                    <LinkIcon sx={{ fontSize: "15px" }} />
                  </Badge>
                  Home
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/product")}
                >
                  <Badge badgeContent={3} sx={{ mr: 1, bgcolor: "#333" }}>
                    <LinkIcon sx={{ fontSize: "15px" }} />
                  </Badge>
                  Products
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/gallery")}
                >
                  <Badge badgeContent={1} sx={{ mr: 1, bgcolor: "#333" }}>
                    <LinkIcon sx={{ fontSize: "15px" }} />
                  </Badge>
                  Gallery
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/contactus")}
                >
                  <Badge badgeContent={1} sx={{ mr: 1, bgcolor: "#333" }}>
                    <LinkIcon sx={{ fontSize: "15px" }} />
                  </Badge>
                  Contact Us
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
          {/* ------------protein values------------ */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> Protein Values
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 3 }}>
              <ThemeProvider theme={theme}>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#PVShrimpPops1"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    Shrimp Pops
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink smooth to="/product#PVFishPop1" style={customstyle}>
                    <LinkIcon sx={{ fontSize: "15px", mr: 0.2 }} /> Fish Pops
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#PVChickenBite"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 0.2 }} /> Chicken
                    Bites
                  </HashLink>
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
          {/* ------------pv kitchen------------ */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> PV Kitchen
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 3 }}>
              <ThemeProvider theme={theme}>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#PVKitchenHomeCooked"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    Home Cooked Meals
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#PVKitchenSalads"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    Salads
                  </HashLink>
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
          {/* ------------ground Masala------------ */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> Ground Masala Paste
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 3 }}>
              <ThemeProvider theme={theme}>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala1"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Fish general paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala2"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Fish green Masala"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Egg red masala paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Egg omam masala paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Chicken red curry paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Chicken green masala paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Chicken white curry paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Beef green & red curry paste"}
                  </HashLink>
                </Typography>
                <Typography variant="body2" component="div">
                  <HashLink
                    smooth
                    to="/product#GroundMasala"
                    style={customstyle}
                  >
                    <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                    {"Mutton Curry Green & red paste"}
                  </HashLink>
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
          {/* ------------contact us------------ */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> Contact Us
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 3 }}>
              <Typography variant="body2" component="div">
                <LocationOnOutlinedIcon sx={{ fontSize: "15px", mr: 1 }} />
                Bondel, Airport Road.
              </Typography>
              <Typography variant="body2" component="div">
                <MailOutlineOutlinedIcon sx={{ fontSize: "15px", mr: 1 }} />
                info@proteinvalues.com
              </Typography>
              <Typography variant="body2" component="div">
                <LocalPhoneOutlinedIcon sx={{ fontSize: "15px", mr: 1 }} />
                +91 9901549741
              </Typography>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="body2"
                  component="a"
                  href=" https://www.facebook.com/proteinvalues/"
                  target="_blank"
                >
                  <FacebookIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Facebook
                </Typography>
                <Typography
                  variant="body2"
                  component="a"
                  href=" https://www.instagram.com/proteinvalues/"
                  target="_blank"
                >
                  <InstagramIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Instagram
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
          {/* ------------Support---------- */}
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              variant="h6"
              component="div"
              color="var(--color-golden)"
              mb={1}
            >
              <FolderIcon sx={{ pb: "5px" }} /> Support
            </Typography>
            <Stack direction="column" spacing={1} sx={{ pl: 3 }}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="body2"
                  component="a"
                  target="_blank"
                  href="https://sanaricafoods.com/"
                >
                  <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Selling Partners : Sana Rica Foods
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/aboutus")}
                >
                  <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                  About Us
                </Typography>

                <Typography
                  variant="body2"
                  component="div"
                  onClick={() => navigate("/faq")}
                >
                  <LinkIcon sx={{ fontSize: "15px", mr: 1 }} />
                  FAQs
                </Typography>
              </ThemeProvider>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
