import {
  Drawer,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import logo from "../../assets/PV-LOGO.png";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

export default function DrawerSmallScreen() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const Data = [
    {
      id: 1,
      name: "Home",
      route: "/",
      path: null,
    },
    {
      id: 2,
      name: "Products",
      route: "/product",
      path: null,
    },
    {
      id: 3,
      name: "About Us",
      route: "/aboutus",
      path: null,
    },
    {
      id: 4,
      name: "Contact Us",
      route: "/contactus",
      path: null,
    },
  ];

  return (
    <>
      <Drawer
        id="Nav"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          component="img"
          src={logo}
          style={{
            width: "auto",
            height: 55,
            marginRight: "auto",
            py: 1,
            paddingTop: 10,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        ></Box>
        <List
          sx={{
            width: 160,
            color: "var(--color-golden)",
            paddingTop: 2,
          }}
        >
          {Data?.map(({ id, name, route, path }, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(route);
                  setOpen(false);
                }}
                href={path}
              >
                <ListItemText primary={name} className="ps-3" />
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpen(!open)}
        sx={{ color: "var(--color-golden)" }}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
}
