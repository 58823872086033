import React from "react";
// import { Row, Col } from "react-bootstrap";
import {
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import FoodItemCarousal from "./FoodItemCarousal";
import Data from "../../Constant/Constant";

function FoodItems() {
  const [selctedItem, setselctedItem] = React.useState(
    Data.FoodItemsMenu[0].list
  );
  const [value, setValue] = React.useState(0);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          gap: { xs: 2, lg: 0 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "25%", lg: "25%" },
          }}
        >
          <Box
            id="foodItemNav"
            sx={{
              overflowX: "hidden",
              display: {
                xs: "block",
                md: "none",
              },
            }}
          >
            <List
              sx={{
                borderRight: {
                  xs: "none",
                  md: "1px solid #e2e2e23b",
                },
                marginRight: {
                  xs: 0,
                  md: 3,
                },
                display: "flex",
                flexDirection: "row",
                alignItems: {
                  xs: "center",
                  md: "start",
                },
                whiteSpace: "nowrap",
                height: "100%",
                overflow: "auto",
              }}
            >
              {Data.FoodItemsMenu?.map((item, index) => {
                return (
                  <ListItem key={item.name + index}>
                    <ListItemText
                      sx={{ width: "max-content" }}
                      primary={item.name}
                      className={
                        selctedItem === item.list
                          ? "active text-lg-start text-center"
                          : "text-lg-start text-center"
                      }
                      onClick={() => {
                        setselctedItem(item.list);
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
              height: "100%",
            }}
          >
            <Tabs
              value={value}
              orientation="vertical"
              // centered={true}
              onChange={(e, val) => setValue(val)}
              // variant="scrollable"
              aria-label="Vertical tabs "
              sx={{
                borderRight: 1,
                borderColor: "divider",
                my: "auto",
              }}
            >
              {Data.FoodItemsMenu?.map((item, index) => {
                return (
                  <Tab
                    key={item.name + index}
                    label={
                      <>
                        <Typography variant="body2" sx={{ textAlign: "left" }}>
                          {item.name}
                        </Typography>
                      </>
                    }
                    onClick={() => setselctedItem(item.list)}
                    sx={{
                      color: "var(--color-golden)",
                      alignItems: "baseline",
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Box>
        <Box sx={{ width: { sm: "100%", md: "75%", lg: "75%" } }}>
          <FoodItemCarousal list={selctedItem} />
        </Box>
      </Box>
    </>
  );
}

export default FoodItems;
