import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/App.js";
import Root from "./Roots/route";
// import Footer from "./Components/Footer/Footer.js";

export default function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route>
          <Route path="*" element={<Root />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
}
