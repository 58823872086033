// import img from "../assets/img.jpg";
import vegsalad from "../assets/VegSalad.jpg";
import pops from "../assets/IMG20190914110235.jpg";
import shrimpPops from "../assets/ShrimpPops1.jpg";
import masalapaste from "../assets/FishGravyPasteCloseup1.jpg";
import masalapasteGallery from "../assets/FishGravyPasteCloseup.jpg";
import fishGreenMasala from "../assets/GreenMasala(1).jpeg";
import chickenSaladMeal from "../assets/chickenSaladMeal.jpg";
import schoolLucnh from "../assets/schoollunch.jpg";
import FishPower from "../assets/FishPower1.jpg";
// -----Gallery---------------
const galleryItems = [
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/meal1.jpg?alt=media&token=736e8de7-1a3c-404c-b40e-d76f3b49cbb7",
    title: "Meal",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops2.jpg?alt=media&token=00f1132f-66e3-4ce9-ad64-0445ada1ee3e",
    title: "Fish Pops",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/detoxSalad.jpg?alt=media&token=c4a38ed6-98f5-4e0d-9282-b6d4da61e8d6",
    title: "Detox Salad",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops1.jpg?alt=media&token=3f35d238-196a-4fbb-9059-541be02cb435",
    title: "Fish Pops",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops4.jpg?alt=media&token=ec887e59-6162-45c9-91bd-7ce4c6e91e91",
    title: "Fish Pops",
  },
  {
    src: pops,
    title: "Pops",
  },

  {
    src: masalapasteGallery,
    title: "Fish Masala Paste",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenSalad.jpg?alt=media&token=c6136b93-f176-4e1b-861b-ca0ef2c6d408",
    title: "Chicken Salad",
  },

  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/pulav.jpg?alt=media&token=6b1e8df2-d520-40c4-a20e-2ee18595c818",
    title: "Veg Pulav",
  },
  {
    src: chickenSaladMeal,
    title: "Chicken Salad Meal",
  },
  {
    src: schoolLucnh,
    title: "School Lunch",
  },
  {
    src: vegsalad,
    title: "Veg Salad",
  },

  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/HomeCookedNonVeg.jpg?alt=media&token=6b80bc72-58c7-43c2-9d88-dd702b1adc6d",
    title: "Non Veg Meal",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ShrimpPops1.jpg?alt=media&token=852a9bf4-8f22-4954-b8d1-052351aafa34",
    title: "Shrimps Pops",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenBites.jpg?alt=media&token=e9bf1d7e-970d-4346-8ee2-4bce6fb4fcf5",
    title: "Chicken Bites",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops3.jpg?alt=media&token=2f85f58a-f801-46e8-98a6-d832845fa6d2",
    title: "Fish Pops",
  },
  {
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ShrimpPops2.jpg?alt=media&token=ac8af87b-a5f3-4bfc-ad63-3d0659f3b83a",
    title: "Shrimps Pops",
  },
  {
    src: FishPower,
    title: "Fish Power",
  },
];
// ------Top Products----------
const topProducts = [
  {
    id: 1,
    name: "Masala Paste",
    src: masalapaste,
    desc: "Stone Ground fresh paste for Mangalorean traditional cooking.",
    path: "/product#GroundMasala",
  },
  {
    id: 2,
    name: "Chicken Bites",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenBites.jpg?alt=media&token=e9bf1d7e-970d-4346-8ee2-4bce6fb4fcf5",
    desc: "Boneless non-processed breaded chicken bites. ",
    path: "/product#PVChickenBite",
  },
  {
    id: 3,
    name: "Fish Pops",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops2.jpg?alt=media&token=00f1132f-66e3-4ce9-ad64-0445ada1ee3e",
    desc: " Bite of fish protein battered and breaded. Protein bite in every pop. ",
    path: "/product#PVFishPop1",
  },
  {
    id: 4,
    name: "Shrimp Pops",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ShrimpPops2.jpg?alt=media&token=ac8af87b-a5f3-4bfc-ad63-3d0659f3b83a",
    desc: " Smaller shrimps battered and breaded for a crispier taste.",
    path: "/product#PVShrimpPops1",
  },
];
// --Ground Masala-------------
const GroundMasala = [
  {
    id: "GroundMasala",
    name: null,
    item: [
      {
        id: "GroundMasala1",
        name: "Fish general paste",
        src: masalapaste,
        desc: "A Manglorean touch fish general paste.",
      },
      {
        id: "GroundMasala2",
        name: "Fish green Masala",
        src: fishGreenMasala,
        desc: "Local farm grown ingredients used.",
      },
    ],
  },
];
// ----Products Page-----------
const PV = [
  {
    id: "PV",
    name: "Protein Values",
    item: [
      {
        id: "PVFishPop1",
        name: "Fish Pops",
        src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/FishPops2.jpg?alt=media&token=00f1132f-66e3-4ce9-ad64-0445ada1ee3e",
        desc: "Bite of fish protein battered and breaded, protein bite in every pop.",
      },
      {
        id: "PVChickenBite",
        name: "Chicken Bites",
        src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenBites.jpg?alt=media&token=e9bf1d7e-970d-4346-8ee2-4bce6fb4fcf5",
        desc: "Fresh chicken with Indian spices and eastern coating",
      },
      {
        id: "PVShrimpPops1",
        name: "Shrimp Pops",
        src: shrimpPops,
        path: "/products",
        desc: "Smaller shrimps battered and breaded for a crispier taste. ",
      },
    ],
  },
];
const pvKitchen = [
  {
    id: "PVkitchen",
    name: "PV Kitchen",
    item: [
      {
        id: "PVKitchenDetoxSalad",
        name: "Detox salad bowl",
        src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/detoxSalad.jpg?alt=media&token=c4a38ed6-98f5-4e0d-9282-b6d4da61e8d6",
        desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      },
      {
        id: "PVKitchenchickenSaladMeal",
        name: "Chicken salad meal ",
        src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenSalad.jpg?alt=media&token=c6136b93-f176-4e1b-861b-ca0ef2c6d408",
        desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      },
      {
        id: "PVKitchenVegSalad",
        name: "Veg salad meal",
        src: vegsalad,
        desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      },
    ],
  },
];
const pvKitchenHomeCookedMeals = [
  {
    id: "PVKitchenHomeCooked",
    name: "Home Cooked Meals",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/HomeCookedNonVeg.jpg?alt=media&token=6b80bc72-58c7-43c2-9d88-dd702b1adc6d",
    desc: "Non veg meals for a daily dose",
  },
  {
    id: "PVKitchenHomeCooked",
    name: "Home Cooked Meals",
    src: schoolLucnh,
    desc: "School Lunch",
  },
  {
    id: "PVKitchenHomeCooked",
    name: "Home Cooked Meals",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/pulav.jpg?alt=media&token=6b1e8df2-d520-40c4-a20e-2ee18595c818",
    desc: "Pulav",
  },
  {
    id: "PVKitchenSalads",
    name: "Salads",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/detoxSalad.jpg?alt=media&token=c4a38ed6-98f5-4e0d-9282-b6d4da61e8d6",
    desc: "Detox salad bowl",
  },
  {
    id: "PVKitchenSalads",
    name: "Salads",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenSalad.jpg?alt=media&token=c6136b93-f176-4e1b-861b-ca0ef2c6d408",
    desc: "Chicken salad meal ",
  },
  {
    id: "PVKitchenSalads",
    name: "Salads",
    src: vegsalad,
    desc: "Veg salad meal",
  },
];
// ---Food Items Section-------
const Salads = [
  {
    name: "Detox salad bowl",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/detoxSalad.jpg?alt=media&token=c4a38ed6-98f5-4e0d-9282-b6d4da61e8d6",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  {
    name: "Chicken salad meal ",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/ChickenSalad.jpg?alt=media&token=c6136b93-f176-4e1b-861b-ca0ef2c6d408",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  {
    name: "Veg salad meal",
    src: vegsalad,
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
];

const HomeCookedMeals = [
  {
    name: "Non veg meals for a daily dose",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/HomeCookedNonVeg.jpg?alt=media&token=6b80bc72-58c7-43c2-9d88-dd702b1adc6d",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  {
    name: "Veg Pulav",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/pulav.jpg?alt=media&token=6b1e8df2-d520-40c4-a20e-2ee18595c818",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
];

const DryMasalaPaste = [
  {
    name: "Dry Masalas",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/dryMasala.webp?alt=media&token=d036bc16-70d3-4396-a168-3e2ab0fd2ca1",
    desc: "",
  },
  {
    name: "Dry Masalas",
    src: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/Masala.jpg?alt=media&token=63c90c55-2b68-4e8a-889a-8b252e58d56a",
    desc: "",
  },
];
const FoodItemsMenu = [
  {
    id: 0,
    name: "Salads",
    list: Salads,
  },
  {
    id: 1,
    name: "Home Cooked Meals",
    list: HomeCookedMeals,
  },
  {
    id: 2,
    name: "Dry Masala Paste",
    list: DryMasalaPaste,
  },
];
// ---overall Data------------
const Data = {
  PV,
  pvKitchen,
  pvKitchenHomeCookedMeals,
  topProducts,
  galleryItems,
  GroundMasala,
  FoodItemsMenu,
};
export default Data;
