import { Box, Typography } from "@mui/material";
import React from "react";
import pvVideo from "../../assets/PVvideo.mp4";
export default function VideoSection() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 3,
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="h4"
            color="var(--color-golden)"
            component="div"
            className="mb-2"
          >
            {"Getting Started with us"}
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start"
          >
            Supporting the local farmers and vendors we created our network.
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start"
          >
            Shrimps (White) from local Manglorean sea.
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start "
          >
            Chicken (Halal) from local farmers we know.
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start"
          >
            Gravy ingredients picked from local experienced farmers for organic
            and nonprocessed elements.
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start"
          >
            We partnered with the manufacturer for Imported quality Indian made
            Panko.
          </Typography>
          <Typography
            variant="h6"
            color="#fff"
            component="div"
            className="text-start"
          >
            Boneless fish from the fisheries in India.
          </Typography>
        </Box>
        <video
          muted
          loop
          autoPlay
          style={{
            height: "500px",
          }}
        >
          <source
            // src="https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/mp4.mp4?alt=media&token=156e78ec-16ef-4e79-b52c-abc6d59b4da5"
            src={pvVideo}
          />
        </video>
      </Box>
    </>
  );
}
