import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
// import Products from "../Products/Products";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
function ProductSwiper(props) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="myProductSwiper"
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
      >
        {props.data?.map(({ id, name, src, desc }, index) => {
          return (
            <SwiperSlide
              className="d-flex h-100"
              style={{
                backgroundColor: "transparent",
                backdropfilter: "blur(12px)",
                alignSelf: "normal",
              }}
              key={index}
            >
              <Card
                key={index + id}
                id={id}
                className="mx-auto"
                sx={{
                  maxWidth: "16rem",
                  height: "19rem",
                  //   alignSelf: "normal",
                  backgroundColor: "#efefef12",
                  backdropfilter: "blur(12px)",
                }}
              >
                <CardMedia
                  sx={{
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                >
                  <img
                    src={src}
                    alt=".."
                    style={{
                      aspectRatio: "16/12",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    className="img-fluid"
                  />
                </CardMedia>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="var(--color-golden)"
                  >
                    {name}
                  </Typography>
                  <Typography variant="body2" color="#fff">
                    {desc}
                  </Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default ProductSwiper;
