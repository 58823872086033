import {
  Box,
  Container,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
  createTheme,
} from "@mui/material";
import Data from "../Constant/Constant.js";
import React from "react";
import { useTheme } from "@emotion/react";
import Footer from "../Components/Footer/Footer.js";
export default function Gallery() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container className="my-5" maxWidth={breakpointIsMatchlg ? "xl" : "lg"}>
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-center my-5"
        >
          Our Gallery
        </Typography>
        <Box
          id="GalleryList"
          sx={{
            width: "100%",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <ImageList
            variant="masonry"
            cols={matchDownMd ? 1 : 2}
            rowHeight={breakpointIsMatchlg ? 400 : matchDownMd ? 300 : 380}
            gap={8}
          >
            {Data.galleryItems.map(({ src, title }, index) => (
              <ImageListItem key={index}>
                <img
                  sty
                  src={src}
                  srcSet={src}
                  alt={title}
                  loading="lazy"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    aspectRatio: 16 / 9,
                  }}
                />
                <ImageListItemBar title={title} height="30%" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
// `${src}?w=248&fit=crop&auto=format`
// `${src}?w=248&fit=crop&auto=format&dpr=2 2x`
