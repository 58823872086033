import React from "react";
import { Row, Col } from "react-bootstrap";
import { Typography } from "@mui/material";
import SubHeading from "../SubHeading/Subheading";

export default function Header() {
  return (
    <>
      <Row className="mt-5 g-5 g-md-0 mx-0">
        <Col md={6} className="d-flex flex-column justify-content-center gap-2">
          <SubHeading title="Celebrating Food With Quality" />
          <Typography variant="h2" className="fw-semibold">
            {" Protein Values "}
          </Typography>
          <Typography variant="body1" className="text-white pe-4">
            {
              " Experience fine dining food on your table. At Protein Values, food prepared will heighten the nutritional value and flavour. "
            }
          </Typography>
        </Col>
        <Col xs={12} md={6}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/meal1.jpg?alt=media&token=736e8de7-1a3c-404c-b40e-d76f3b49cbb7"
            alt="Protein Values"
            className="w-100 rounded-2"
          />
        </Col>
      </Row>
    </>
  );
}
