import React from "react";
import {
  Card,
  // Button,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
export default function FoodItemCard({ name, src, desc, price }) {
  return (
    <>
      <Card
        sx={{
          backgroundColor: "transparent",
        }}
        className="d-flex flex-column mt-auto p-2 w-100"
      >
        <CardMedia
          sx={{
            borderRadius: "15%",
            overflow: "hidden",
            width: "150px",
            boxShadow: "0 4px 4px rgba(248 ,176 ,74, 0.5)",
            margin: "auto",
          }}
        >
          <img
            src={src}
            alt=".."
            className="img-fluid"
            style={{
              aspectRatio: "4/4",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </CardMedia>
        <CardContent className="mx-auto p-3 ">
          <Typography
            variant="h6"
            component="h6"
            color="var(--color-golden)"
            className="text-center"
          >
            {name}
          </Typography>
          {/* <Typography variant="body2" color="#fff" className="text-center">
            {desc}
          </Typography> */}
        </CardContent>
      </Card>
    </>
  );
}
