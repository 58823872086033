import * as React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Collapse,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import logo from "../../assets/PV-LOGO.png";
import DrawerSmallScreen from "./Drawer.js";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import { Stack } from "@mui/system";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { HashLink } from "react-router-hash-link";
const Pages = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "Products",
    path: null,
  },
  {
    id: 3,
    name: "Gallery",
    path: "/gallery",
  },
  {
    id: 4,
    name: "About Us",
    path: "/aboutus",
  },
  {
    id: 5,
    name: "Contact us",
    path: "/contactus",
  },
];
const themeMenuList = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#0c0c0c",
          boxShadow: 0,
          border: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 0,
          background: "transparent",
        },
      },
    },
  },
});

export default function ButtonAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  // const [open3, setOpen3] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpointIsMatch = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenNav = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNav = () => {
    setAnchorEl(null);
  };
  // const path = "/product#ShrimpPops2";
  return (
    <>
      <AppBar
        id="Appbar"
        position="sticky"
        className="shadow-sm py-2"
        sx={{ backgroundColor: "#0c0c0c", color: "var(--color-golden)" }}
      >
        <Box className="w-100 d-flex flex-row justify-content-between align-items-center px-md-5 px-3 py-0">
          <Box
            component="img"
            src={logo}
            style={{
              width: "auto",
              height: 70,
              marginRight: "auto",
              py: 1,

              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          ></Box>
          {breakpointIsMatch ? null : (
            <>
              <Toolbar className="w-100 d-flex justify-content-end p-0">
                <Stack direction="row" spacing={2}>
                  <Button
                    sx={{ color: "#f8b04a" }}
                    onClick={() => navigate("/")}
                  >
                    Home
                  </Button>
                  <Button
                    sx={{ color: "#f8b04a" }}
                    id="product-btn"
                    onClick={handleOpenNav}
                  >
                    Products
                  </Button>
                  <Button
                    sx={{ color: "#f8b04a" }}
                    onClick={() => navigate("/gallery")}
                  >
                    Gallery
                  </Button>
                  <Button
                    sx={{ color: "#f8b04a" }}
                    onClick={() => navigate("/aboutus")}
                  >
                    About Us
                  </Button>
                  <Button
                    sx={{ color: "#f8b04a" }}
                    onClick={() => navigate("/contactus")}
                  >
                    Contact Us
                  </Button>
                </Stack>
                <ThemeProvider theme={themeMenuList}>
                  <Menu
                    id="product-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseNav}
                    sx={{ border: 0 }}
                  >
                    <MenuItem
                      sx={{ color: "#f8b04a", display: "flex" }}
                      onClick={() => setOpen1(!open1)}
                    >
                      Protein Values
                      {open1 ? (
                        <ExpandLess sx={{ marginLeft: "auto" }} />
                      ) : (
                        <ExpandMore sx={{ marginLeft: "auto" }} />
                      )}
                    </MenuItem>
                    <Collapse in={open1} timeout="auto" unmountOnExit>
                      <Stack
                        direction="column"
                        alignItems="start"
                        pl={2}
                        spacing={1}
                      >
                        {/* <HashLink
                          smooth
                          to="/product#PVBreadedShrimps1"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {" Breaded Shrimps"}
                        </HashLink> */}
                        <HashLink
                          smooth
                          to="/product#PVShrimpPops1"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Shrimp Pops"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#PVFishPop1"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Fish Pops"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#PVChickenBite"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Chicken Bites"}
                        </HashLink>
                      </Stack>
                    </Collapse>
                    <MenuItem
                      sx={{ color: "#f8b04a", display: "flex" }}
                      onClick={() => setOpen2(!open2)}
                    >
                      PV Kitchen
                      {open2 ? (
                        <ExpandLess sx={{ marginLeft: "auto" }} />
                      ) : (
                        <ExpandMore sx={{ marginLeft: "auto" }} />
                      )}
                    </MenuItem>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                      <Stack
                        direction="column"
                        alignItems="start"
                        pl={2}
                        spacing={1}
                      >
                        <HashLink
                          smooth
                          to="/product#PVKitchenHomeCooked"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {" Home Cooked Meals"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#PVKitchenSalads"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Salads"}
                        </HashLink>
                      </Stack>
                    </Collapse>
                    <MenuItem
                      sx={{ color: "#f8b04a", display: "flex" }}
                      // onClick={() => setOpen3(!open3)}
                    >
                      <HashLink
                        smooth
                        to="/product#GroundMasala"
                        style={{
                          color: "#f8b04a",
                          textTransform: "capitalize",
                          textDecoration: "none",
                        }}
                        onClick={handleCloseNav}
                      >
                        {"Ground Masala Paste"}
                      </HashLink>
                      {/* {open3 ? (
                        <ExpandLess sx={{ marginLeft: "8px" }} />
                      ) : (
                        <ExpandMore sx={{ marginLeft: "8px" }} />
                      )} */}
                    </MenuItem>
                    {/* <Collapse in={open3} timeout="auto" unmountOnExit>
                      <Stack
                        direction="column"
                        alignItems="start"
                        pl={2}
                        spacing={1}
                      >
                        <HashLink
                          smooth
                          to="/product#GroundMasala1"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Fish general paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala2"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Fish green Masala"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala3"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Egg red masala paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala4"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Egg omam masala paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala5"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Chicken red curry paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala6"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Chicken green masala paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala7"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Chicken white curry paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala8"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Beef green & red curry paste"}
                        </HashLink>
                        <HashLink
                          smooth
                          to="/product#GroundMasala9"
                          style={{
                            color: "#f8b04a",
                            fontSize: "13px",
                            textTransform: "capitalize",
                            textDecoration: "none",
                          }}
                          onClick={handleCloseNav}
                        >
                          {"Mutton Curry Green & red paste"}
                        </HashLink>
                      </Stack>
                    </Collapse> */}
                  </Menu>
                </ThemeProvider>
              </Toolbar>
            </>
          )}
          {breakpointIsMatch ? <DrawerSmallScreen Pages={Pages} /> : null}
        </Box>
      </AppBar>
    </>
  );
}
