import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "./font/style.css";
let theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Optima, Arial",
    },
    h2: {
      fontFamily: "Optima, Arial",
    },
    h3: {
      fontFamily: "Optima, Arial",
    },
    h4: {
      fontFamily: "Optima, Arial",
    },
    h5: {
      fontFamily: "Optima, Arial",
    },
    h6: {
      fontFamily: "Optima, Arial",
    },
    subtitle1: {
      fontFamily: "Optima, Arial",
      color: "#5D5D5D",
    },
    subtitle2: {
      fontFamily: "Optima, Arial",
    },
    body1: {
      fontFamily: "Optima, Arial",
    },
    body2: {
      fontFamily: "Optima, Arial",
    },
    button: {
      fontFamily: "Optima, Arial",
    },
    caption: {
      fontFamily: "Optima, Arial",
    },
    overline: {
      fontFamily: "Optima, Arial",
    },
  },
});
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
