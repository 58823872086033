import {
  Box,
  Container,
  createTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Footer from "../Components/Footer/Footer.js";

const Data = [
  {
    title:
      "What makes Protein Values different from other frozen ready-to-cook products?",
    desc: " Protein Values products are Free from harmful chemicals, preservatives, egg, yoghurt, imitation meat and cooked meat. So it’s real food.",
  },
  {
    title: "Is it safe for small kids to feed frozen products?",
    desc: "Yes. The food prepared is free from preservatives, allergy causing elements like yoghurt or egg.",
  },
  {
    title: "Is Protein Values manufacturing processed food?",
    desc: " Yes. But only mechanical processing. Chemical operations are not performed on food.",
  },
  {
    title: "How to order daily meals? ",
    desc: "Call us or whatsapp to book your meals.",
  },
  {
    title: "What is the latest time to place an order for lunch or dinner? ",
    desc: "By 7am we close orders for the day.",
  },
  {
    title: "What is included in detox salads and what is the quantity? ",
    desc: "Detox salad comes with corn, sprouted pulses, cucumbers, carrots, capsicum 3 colors, lettuce leaves & other seasonal greens. Fruits: seasonal, pineapple, green apple, grapes, apple & pomegranate. Comes in 3 different sizes: 1000ml bowl, 500ml bowl, 250ml bowl. ",
  },
  {
    title: "What is veg salad meal?",
    desc: "Kidney beans cooked, with the simple detox salad.",
  },
  {
    title: "How do you pack meals?",
    desc: "We use disposable packaging. But for school kids and hostel children we provide 2 sets of steel containers which get returned while delivering next days’ meals.",
  },
];

export default function FAQs() {
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container className="my-5" maxWidth={breakpointIsMatchlg ? "xl" : "lg"}>
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-start my-5"
        >
          FAQs
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {Data?.map(({ title, desc }, index) => {
            return (
              <>
                <Typography
                  key={title}
                  variant="h6"
                  color="var(--color-golden)"
                  className="text-start"
                >
                  {title}
                </Typography>
                <Typography
                  variant="body"
                  color="#fff"
                  className="text-start mb-3"
                >
                  - {desc}
                </Typography>
              </>
            );
          })}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
