import React from "react";
import { Col, Row } from "react-bootstrap";
import Footer from "../Footer/Footer.js";
import Alert from "@mui/material/Alert";
import {
  Container,
  Typography,
  TextField,
  Button,
  styled,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import submitForm from "./contactform.js";

const CustomTextField = styled(TextField)({
  background: "#efefef12",
  "& input": {
    color: "#fff !important",
  },
  "& textarea": {
    color: "#fff !important",
  },
  "& label": {
    color: "var(--color-golden)",
  },
  "& label.Mui-focused": {
    color: "var(--color-golden)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "grey",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--color-golden)",
    },
  },
});

export default function Contactpage() {
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    submitForm(
      data.get("firstName"),
      data.get("lastName"),
      data.get("email"),
      data.get("phone"),
      data.get("message")
    );
  };

  return (
    <>
      <Container
        maxWidth={breakpointIsMatchlg ? false : "lg"}
        sx={{
          width: {
            xs: "100%",
            md: "60%",
          },
          my: {
            xs: 5,
            md: 4,
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="success" color="info" id="alert">
          Thank you, we received your mail !
        </Alert>
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-start mt-5"
        >
          Contact Us
        </Typography>
        <form id="contact-form" onSubmit={handleSubmit}>
          <Row className="g-3 m-0 p-4">
            <Col sx={12} md={6}>
              <CustomTextField
                focused
                label="First Name"
                id="firstName"
                name="firstName"
                variant="outlined"
                className="w-100"
                required
              />
            </Col>
            <Col sx={12} md={6}>
              <CustomTextField
                label="Last Name"
                id="lastName"
                name="lastName"
                variant="outlined"
                className="w-100"
                required
              />
            </Col>
            <Col md={12}>
              <CustomTextField
                label="Email"
                id="email"
                name="email"
                type="email"
                variant="outlined"
                className="w-100"
                required
              />
            </Col>
            <Col md={12}>
              <CustomTextField
                label="Contact Number"
                placeholder="+91"
                id="phone"
                name="phone"
                type="tel"
                variant="outlined"
                className="w-100"
                required
              />
            </Col>
            <Col md={12}>
              <CustomTextField
                type="text"
                label="Message"
                id="message"
                multiline
                rows={4}
                name="message"
                variant="outlined"
                className="w-100"
                required
              />
            </Col>
            <Col md={12}>
              <Button
                variant="contained"
                type="submit"
                className="w-100 py-2 rounded-0 fw-bold"
                sx={{
                  color: "#000",
                  background: "var(--color-golden)",
                  "&:hover": {
                    background: "var(--color-golden) !important",
                    boxShadow: "0 0 0 px rgba(248 ,176 ,74, 0.5) !important",
                  },
                }}
              >
                Send
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
      <Footer />
    </>
  );
}
