import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Data from "../Constant/Constant";
import Footer from "../Components/Footer/Footer";
import Products from "../Components/Products/Products";
import { Col, Row } from "react-bootstrap";
import ProductSwiper from "../Components/Card/ProductSwiper";
const GroundMasalaList = [
  "Fish general paste",
  "Fish green Masala",
  "Egg red masala paste",
  "Egg omam masala paste",
  "Chicken red curry paste",
  "Chicken green masala paste",
  "Chicken white curry paste",
  "Beef green and red curry paste",
  "Mutton Curry Green & red paste",
];

function Product() {
  const themelg = createTheme({
    breakpoints: {
      values: {
        laptop: 1500,
      },
    },
  });
  const breakpointIsMatchlg = useMediaQuery(themelg.breakpoints.up("laptop"));
  return (
    <>
      <Container sx={{ my: 5 }} maxWidth={breakpointIsMatchlg ? "xl" : "lg"}>
        <Typography
          variant="h3"
          color="var(--color-golden)"
          className="text-center my-5"
        >
          Our Products
        </Typography>
        {/* pv */}
        {Data.PV?.map(({ name, item }, index) => {
          return <Products key={index} name={name} item={item} />;
        })}
        {/* pv kitchen */}
        <Typography
          variant="h4"
          component="h4"
          color="var(--color-golden)"
          className="text-center mt-5 mb-3 pt-lg-3"
          sx={{ textShadow: "0px 0px 4px rgba(248 ,176 ,74, 0.5)" }}
        >
          {"PV Kitchen"}
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          color="#fff"
          className="text-center mb-5"
          sx={{ textShadow: "0px 0px 4px rgba(248 ,176 ,74, 0.5)" }}
        >
          {
            "Pre Ordered food given daily to school children, aged folks, retirees, hospital discharged patients. Basic rice curry non veg meals. Chicken & fish included. For patients, customised food prepared."
          }
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Data.pvKitchenHomeCookedMeals?.map(
            ({ id, name, src, desc }, index) => {
              return (
                <>
                  <Card
                    key={index + id}
                    id={id}
                    sx={{
                      maxWidth: "17rem",
                      alignSelf: "normal",
                      backgroundColor: "#efefef12",
                      backdropfilter: "blur(12px)",
                    }}
                  >
                    <CardMedia
                      sx={{
                        overflow: "hidden",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        src={src}
                        alt=".."
                        style={{
                          aspectRatio: "16/12",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        className="img-fluid"
                      />
                    </CardMedia>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="var(--color-golden)"
                      >
                        {name}
                      </Typography>
                      <Typography variant="body2" color="#fff">
                        {desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              );
            }
          )}
        </Box>

        {/* ground masala */}
        {/* */}
        <Box className="my-5">
          <Typography
            id="GroundMasala"
            variant="h4"
            component="h4"
            color="var(--color-golden)"
            className="text-center my-3 pt-md-3"
          >
            Grounded Masala Paste & Power
          </Typography>
          <Row className="m-0 g-3">
            <Col sx={12} md={12} lg={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: {
                    xs: "center",
                    md: "start",
                    lg: "start",
                  },
                  gap: 1,
                  height: "100%",
                }}
              >
                {GroundMasalaList?.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      variant="subtitle1"
                      color="var(--color-golden)"
                      className=""
                    >
                      * {item}
                    </Typography>
                  );
                })}
              </Box>
            </Col>
            <Col sx={12} md={12} lg={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ProductSwiper data={Data.GroundMasala[0].item} />
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Product;
