export default function submitForm(firstname, lastname, email, phone, message) {
  console.log(firstname, lastname, email, message);

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    to: "info@proteinvalues.com",
    subject: `Contacted by ${firstname} ${lastname}`,
    text: `Name: ${firstname} ${lastname},
               Email: ${email},
               Message: ${message},
               `,
    html: `
      <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
      <tr>
      <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #000;color: #f8b04a;font-weight:bold;width:30%">Name</th>
      <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #000;color: #f8b04a;font-weight:bold;">Value</th>
    </tr>
      <tr>
        <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
        <td style="border: 1px solid #ddd;padding: 8px;">${firstname} ${lastname}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
        <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
      </tr>
      <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Contact Number</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${phone}</td>
    </tr>
      <tr>
        <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
        <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
      </tr>
    </table>
    
      `,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://asia-south1-biz-contact-form-8001a.cloudfunctions.net/sendMailFromCompany",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
    })
    .catch((error) => console.log("error", error));

  // Show alert
  document.querySelector("#alert").style.display = "flex";

  // Hide alert after 3 seconds
  setTimeout(function () {
    document.querySelector("#alert").style.display = "none";
  }, 3000);
  // Clear form
  document.getElementById("contact-form").reset();
}
