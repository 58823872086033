import React from "react";
import spoon from "../../assets/spoon.svg";
import "./SubHeading.css";
const SubHeading = (props) => (
  <div style={{ marginBottom: "1rem" }}>
    <p className="p__cormorant pb-0 mb-0">{props.title}</p>
    <img src={spoon} alt="spoon" className="spoon__img" />
  </div>
);

export default SubHeading;
