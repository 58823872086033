import { Box, Typography } from "@mui/material";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import TestimonialCard from "./TestimonialCard.js";

const Profile = [
  {
    name: "Pam Lobo",
    place: "India",
    img: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/img1.jpeg?alt=media&token=a5aa4182-ecc1-4045-8c48-29451f491656",
    desc: "I am a mother of 4 kids and a career woman. My old mother aged 80 years also stays with me. Cooking has always been a concern for me in my busy schedule. I was introduced to Kavitha through a friend. She is a great cook, cooking authentic yummy Mangalorean dishes. She is a knight in shining armor. I  have ordered food from her and never have been disappointed.  Thank you Kavitha.",
    date: "05/02/2023",
  },
  {
    name: "Davion",
    place: "India",
    img: "https://firebasestorage.googleapis.com/v0/b/protein-value-bucket-b74c4.appspot.com/o/img2.jpeg?alt=media&token=33bd86f7-3b83-4a35-bbdd-5bdcd031a3e3",
    desc: "The food is tasty home-made and non vegetarian. The tiffin system is effective. Everyday there is something new to look forward to. Thank you Kavitha aunty.",
    date: "21/02/2023",
  },
];

export default function Testimonial() {
  return (
    <Box
      className="text-center"
      sx={{
        pt: {
          xs: 5,
          md: 3,
        },
      }}
    >
      <Typography
        variant="h3"
        component="h3"
        className="fw-bold mb-3 mb-md-0"
        sx={{
          fontSize: { xs: "1.75rem", sm: "2.2rem", md: "2.38rem" },
        }}
      >
        TESTIMONIAL
      </Typography>

      <Swiper
        id="testimonialSwiper"
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper my-0 my-md-5"
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
      >
        {Profile?.map(({ name, place, img, desc, date }, index) => {
          return (
            <SwiperSlide
              className="me"
              style={{ height: "auto", backgroundColor: "transparent" }}
              key={index}
            >
              <TestimonialCard
                name={name}
                place={place}
                src={img}
                desc={desc}
                date={date}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
