import { Typography, Box, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { Row, Col } from "react-bootstrap";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Logo from "../../assets/PV-LOGO.png";
import { useNavigate } from "react-router-dom";
const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "var(--color-golden)",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            color: "var(--color-golden)",
          },
        },
      },
    },
  },
});

function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Row className="mx-0 mt-5 pt-4 px-2 pt-5">
        <Col
          sx={12}
          md={4}
          className="d-flex flex-column justify-content-center align-items-md-center gap-4 mb-3 mb-md-auto"
        >
          <Box sx={{ height: 70, width: 210 }}>
            <img src={Logo} alt="logo" className="img-fluid w-100" />
          </Box>
          <Box className="d-flex justify-content-md-start align-items-baseline gap-2 ">
            <Typography variant="body2" component="div">
              Connect with us
            </Typography>
            <Typography
              variant="body2"
              component="a"
              href=" https://www.facebook.com/proteinvalues/"
              target="_blank"
            >
              <FacebookIcon
                sx={{ color: "#f8b04a", cursor: "pointer", fontSize: "20px" }}
              />
            </Typography>
            <Typography
              variant="body2"
              component="a"
              href=" https://www.instagram.com/proteinvalues/"
              target="_blank"
            >
              <InstagramIcon
                sx={{ color: "#f8b04a", cursor: "pointer", fontSize: "20px" }}
              />
            </Typography>
          </Box>
        </Col>
        <Col
          sx={12}
          md={4}
          className="d-flex flex-column justify-content-start align-items-start gap-3 ps-md-5 mb-3 mb-md-auto"
        >
          <Typography variant="body1" color="#fff">
            Contact
          </Typography>
          <Typography variant="body2" component="div">
            <LocationOnOutlinedIcon
              sx={{ color: "var(--color-golden)", marginRight: 1 }}
            />
            Bondel, Airport Road.
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="mailto:info@proteinvalues.com"
            sx={{
              color: "inherit",
              textDecoration: "none",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            <MailOutlineOutlinedIcon
              sx={{ color: "var(--color-golden)", marginRight: 1 }}
            />
            info@proteinvalues.com
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="tel:9901549741"
            sx={{
              color: "inherit",
              textDecoration: "none",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            <LocalPhoneOutlinedIcon
              sx={{ color: "var(--color-golden)", marginRight: 1 }}
            />
            +91 9901549741
          </Typography>
        </Col>
        <Col
          sx={12}
          md={4}
          className="d-flex flex-column justify-content-center align-items-start gap-3"
        >
          <Typography variant="body1" color="#fff">
            Support
          </Typography>
          <ThemeProvider theme={theme}>
            <Typography
              variant="body2"
              component="div"
              onClick={() => navigate("/faq")}
            >
              FAQs
            </Typography>
            {/* <Typography
              variant="body2"
              component="a"
              href="https://sanaricafoods.com/"
              target="_blank"
              sx={{ textDecoration: "none" }}
            >
              Selling Partners : Sana Rica Foods
            </Typography> */}
            <Typography
              variant="body2"
              component="div"
              onClick={() => navigate("/aboutus")}
            >
              About us
            </Typography>
            <Typography
              variant="body2"
              component="div"
              onClick={() => navigate("/sitemap")}
            >
              Sitemap
            </Typography>
          </ThemeProvider>
        </Col>
      </Row>
      <Typography
        variant="subtitle1"
        component="div"
        className="text-lg-center pt-4 px-4"
      >
        Copyright @{new Date().getFullYear()} proteinvalues.com .Reproduction is forbidden unless authorized. All rights reserved. |
        Powered by NuLark Solutions
      </Typography>
    </>
  );
}

export default Footer;
